import {Injectable} from '@angular/core'
import {BsModalService} from 'ngx-bootstrap/modal'

import {DialogModel} from '@web/core/models/local/dialog.model'
import {DialogComponent} from '@web/core/components/dialog/dialog.component'

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(
    private bsModalService: BsModalService,
  ) {
  }

  show(options: DialogModel, className?: string) {
    this.bsModalService.show(DialogComponent, {initialState: {options}, class: className})
  }

  error(options: DialogModel) {
    if (!options.title) {
      options.title = 'errors.title'
    }
    if (!options.message) {
      options.message = 'errors.unknown_error'
    }
    this.bsModalService.show(DialogComponent, {initialState: {options}})
  }
}
